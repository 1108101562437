/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Text, Image, Slideshow, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Hodiny s Ritou"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>


        <Column className="css-ysnjzo --parallax pb--60" style={{"marginTop":0,"paddingTop":7}} name={"nwknmkrlfih"} layout={"l13"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37992/a1b6ce9986794cc28c5e606c93d6f647_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/a1b6ce9986794cc28c5e606c93d6f647_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37992/a1b6ce9986794cc28c5e606c93d6f647_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/a1b6ce9986794cc28c5e606c93d6f647_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37992/a1b6ce9986794cc28c5e606c93d6f647_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37992/a1b6ce9986794cc28c5e606c93d6f647_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/a1b6ce9986794cc28c5e606c93d6f647_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/a1b6ce9986794cc28c5e606c93d6f647_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--2 flex--center" style={{"paddingTop":29}} columns={"2"} anim={"2"}>
            
            <ColumnWrapper style={{"maxWidth":540}}>
              
              <Subtitle className="subtitle-box subtitle-box--left fs--24" style={{"maxWidth":541,"paddingRight":0}} content={"<span style=\"font-style: italic;\">Já se z tebe zblázním!&nbsp;</span>"}>
              </Subtitle>

              <Title className="title-box title-box--left" content={"Hodiny s Ritou"}>
              </Title>

              <Text className="text-box text-box--left lh--14" style={{"marginTop":27}} content={"Autor: <span style=\"font-weight: bold;\">Willy Russell</span>\n<br>Překlad: <span style=\"font-weight: bold;\">Pavel Dominik\n</span><br>Režie: <span style=\"font-weight: bold;\">Martin Vokoun</span>\n<br>Osoby a obsazení: <br>&nbsp; &nbsp; &nbsp;Rita – <span style=\"font-weight: bold;\">Kateřina Marie Fialová</span><br>&nbsp; &nbsp; &nbsp;Frank – <span style=\"font-weight: bold;\">Vasil Fridrich &nbsp;</span><br>Scéna: <span style=\"font-weight: bold;\">Adam Pitra</span><br>Kostýmy: <span style=\"font-weight: bold;\">Katarína Hollá</span><br><br>"}>
              </Text>

              <Text className="text-box text-box--left fs--18 lh--14" content={"Premiéra: 13. února 2025&nbsp;<br>Producent: Kateřina Schauerová – Marek Sucharda <br>Autorská a provozovací práva: &nbsp;<br>&nbsp; &nbsp; &nbsp;Kateřina Schauerová – Marek Sucharda <br>Délka představení: 1 hodina 45 minut + přestávka <br>V Praze hrajeme: Studio DVA divadlo – Malá scéna<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":582}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37992/151a1ec2d84b42cd81ef6ef4ec44b76e_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37992/151a1ec2d84b42cd81ef6ef4ec44b76e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/151a1ec2d84b42cd81ef6ef4ec44b76e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/151a1ec2d84b42cd81ef6ef4ec44b76e_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow style={{"backgroundColor":"rgba(255,255,245,1)","marginBottom":0,"paddingBottom":9}} name={"xdhzme6fvy"} effect={"fade"} autoplay={false} infinite={false} transitionDuration={6}>
          
          <Column className="js-anim  --anim2" style={{"paddingTop":0,"paddingBottom":0}} anim={"2"}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" style={{"backgroundColor":"rgba(251,247,245,1)","paddingBottom":9,"paddingLeft":0,"paddingRight":0,"maxWidth":1293}} columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/6382902e5db84bb88b617106165aff36_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/6382902e5db84bb88b617106165aff36_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/6382902e5db84bb88b617106165aff36_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/6382902e5db84bb88b617106165aff36_s=860x_.jpg 860w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"<span style=\"font-weight: bold;\">Frank</span> je <span style=\"font-weight: bold;\">světem unavený univerzitní profesor</span>, který ztratil vášeň pro výuku, občas dokonce chodí před studenty v podroušeném stavu. <br><span style=\"font-weight: bold;\">Prostořeká kadeřnice Rita</span> touží změnit od základů svůj život, vymanit se z ubíjejících stereotypů svého prostředí, a proto se přihlásí k dálkovému studiu literatury. <br>Její setkání s Frankem se zpočátku jeví jako střet dvou naprosto odlišných světů… Jak odlišné jsou? A kolik jsme ochotni zaplatit za sebeúctu? <br><span style=\"font-weight: bold;\">Vtipná a moudrá komedie Willyho Russella</span> nabízí mnohem víc než odpovědi na tyto otázky."}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <SeparateLine style={{"marginTop":2,"paddingTop":0,"paddingBottom":3}} name={"9jymho2lq5"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-hn955b js-anim  --anim2 --left --parallax pb--60 pt--60" anim={"2"} name={"znlguzawrv"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37992/c7f04ab4ec064281ba6532c4abf97b66_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/c7f04ab4ec064281ba6532c4abf97b66_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37992/c7f04ab4ec064281ba6532c4abf97b66_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/c7f04ab4ec064281ba6532c4abf97b66_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37992/c7f04ab4ec064281ba6532c4abf97b66_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37992/c7f04ab4ec064281ba6532c4abf97b66_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/c7f04ab4ec064281ba6532c4abf97b66_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/c7f04ab4ec064281ba6532c4abf97b66_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--2 flex--top" style={{"maxWidth":1300}} anim={"2"} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Willy Russell"}>
              </Title>

              <Text className="text-box text-box--justify fs--18" style={{"maxWidth":650}} content={"Willy Russell se narodil v roce 1947 a vyrůstal na předměstí Liverpoolu. Jeho rodiče pracovali v knižním nakladatelství, takže ho v četbě podporovali. Školu opustil v 15ti letech, stal se dámským kadeřníkem a dokonce měl svůj vlastní kadeřnický salón. Kadeřníkem  byl šest let a jak sám píše, nebyl příliš dobrým kadeřníkem, ale byl velmi dobrým posluchačem. Také zpíval na místní folkové scéně. Vyzkoušel ještě několik dalších profesí – balič ponožek ve skladu, čistič nosníků v automobilce Ford, atd. Nakonec se ve dvaceti letech vrátil ke studiu, vystudoval St. Catherine’s College of Education, setkal se svojí ženou Annie, která ho nasměrovala k divadlu a stal se učitelem.&nbsp;<br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--18" content={"Jeho první úspěch byla divadelní hra o Beatles nazvaná<span style=\"font-style: italic;\"> John, Paul, George, Ringo ... and Bert</span>. Premiéru měla v Liverpoolu, ale brzy se přenesla do West Endu v Londýně, kde vyhrála tři ceny: Evening Standard Award, London Theatre Critics Awards a Plays and Players Award. "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box text-box--justify fs--18" style={{"maxWidth":650}} content={"Hry W. Russella – částečně autobiografická hra <span style=\"font-style: italic;\">Educating Rita</span>, muzikál <span style=\"font-style: italic;\">Blood Brothers</span>, ke kterému také Willy Russell napsal hudbu, <span style=\"font-style: italic;\">Shirley Valentine</span> a další divadelní i televizní projekty – byly vždy obsazeny známými herci a měly velký divácký ohlas. <br>"}>
              </Text>

              <Text className="text-box text-box--justify fs--18" content={"V roce 2000 vydal Willy Russell svou první novelu <span style=\"font-style: italic;\">The Wrong Boy</span>. Kromě divadla a filmu Willy Russell skládá od 60. let i písně a ke svým hrám skládá hudbu. V letech 2002/2003 vydal své vlastní album <span style=\"font-style: italic;\">Hoovering the Moon</span> a podílel se také na albu svého kamaráda Tima Firthe <span style=\"font-style: italic;\">Harmless Flirting</span>. Jejich spolupráce – mix hudby, písní a mluveného slova – známá jako <span style=\"font-style: italic;\">The Singing Playwrights </span>vyústila živým koncertem v Edinburghu v roce 2004 během festivalu Fringe Festival.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"marginTop":2,"paddingTop":0,"paddingBottom":5}} name={"fpk6ue5iee"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="js-anim  --anim2 --center" style={{"marginTop":0,"paddingTop":5,"paddingBottom":0}} name={"r8nodztltl"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":632,"marginTop":3,"paddingLeft":0,"marginBottom":0,"paddingRight":0,"paddingBottom":3}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":400,"paddingTop":0,"paddingLeft":0,"paddingBottom":2}}>
              
              <Title className="title-box title-box--left fs--16 w--500 ls--001 lh--14" style={{"maxWidth":450,"paddingLeft":32,"paddingRight":0}} content={"<span style=\"color: var(--color-blend--15);\">ADF | agentura divadelní fantazie<br>Kateřina Schauerová&nbsp;<br>Marek Sucharda</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"marginTop":0,"paddingTop":0,"paddingBottom":1}}>
              
              <Text className="text-box text-box--left fs--16 lh--14" style={{"maxWidth":503,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"color: var(--color-custom-1--15);\"><a href=\"mailto:adf@adf.cz?subject=Zpr%C3%A1va+z+webu\">adf@adf.cz</a> | <a href=\"/\">www.adf.cz</a><br>+420 603 219 657 | <a href=\"mailto:katerina@adf.cz?subject=Zpr%C3%A1va+z+webu\">katerina@adf.cz</a><br>+420 775 262 617 | <a href=\"mailto:marek@adf.cz?subject=Zpr%C3%A1va+z+webu\">marek@adf.cz</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":2}}>
              
              <Text className="text-box text-box--center fs--12" content={"© 2025 ADF | Kateřina Schauerová, Marek Sucharda"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}